<template>
  <div class="personalComfireIndex-container">
    <router-view/>
  </div>
  
</template>

<script>
export default {
  name: 'personalComfireIndex',
  components: {
  }
}

</script>

<style>
</style>
